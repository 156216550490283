import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LoginRedirectService } from '../services/login-redirect.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Auth state Guard
 */
export class SignedInGuard implements CanActivate {

  /**
   * Creates a SignedInGuard instance
   * @param {AuthService} authService Auth service instance
   * @param {Router} router Router instance
   */
  constructor(private authService:AuthService, private LoginRedirect:LoginRedirectService) { }

  /**
   * Returns if the user is authenticated
   * @param next 
   * @param state 
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.authService.isAuthenticated().pipe(map(loggedIn => {

        if(loggedIn) {
          return true;
        }
        else {
          this.LoginRedirect.login();
          return false;
        }

      }));
    
    }
}
