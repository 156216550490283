import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './accounts/login/login.component';
import { LogoutComponent } from './accounts/logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthService } from './services/auth.service';
import { SetupComponent } from './accounts/setup/setup.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { AccountsComponent } from './accounts/accounts.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { AbstraktyUserService } from './services/abstrakty-user.service';
import { LoginRedirectService } from './services/login-redirect.service';
import { HttpClientModule } from '@angular/common/http';
import { DashboardMenuComponent } from './dashboard/dashboard-menu/dashboard-menu.component';
import { DashboardDesktopComponent } from './dashboard/dashboard-desktop/dashboard-desktop.component';
import { DashboardMobileComponent } from './dashboard/dashboard-mobile/dashboard-mobile.component';
import {MatIconModule} from '@angular/material/icon';
import { DeniedComponent } from './dashboard/denied/denied.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    DashboardComponent,
    SetupComponent,
    AccountsComponent,
    DashboardMenuComponent,
    DashboardDesktopComponent,
    DashboardMobileComponent,
    DeniedComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    HttpClientModule,
    MatIconModule
  ],
  providers: [
    AuthService,
    LoginRedirectService,
    AbstraktyUserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
