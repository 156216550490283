import { Injectable } from '@angular/core';
import { AbstraktyUser } from '../models/abstrakty-user';
import { AuthService } from './auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Roles } from '../enums/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AbstraktyUserService {

  document:AngularFirestoreDocument<AbstraktyUser>;
  userObservable:Observable<AbstraktyUser>;
  user:AbstraktyUser = {
    id: "",
    name: "",
    lastName: "",
    picture: "",
    mail: "",
    university: "",
    ocupation: "",
    twiloToken: "",
    setUp: false,
    role: 1
  };
  isLoaded: boolean = false;

  constructor(private AuthService:AuthService, private afs:AngularFirestore, private router:Router) { }

  loadUser(uid:string) {
    this.document = this.afs.doc<AbstraktyUser>('users/' + uid);
    console.log(this.document)
    this.userObservable = this.document.valueChanges();
    this.user.id = "1";
    this.userObservable.subscribe(e => {
      if(e != undefined) {
        this.user = e;
        this.isLoaded = true;
      }
      if(e != undefined && e.setUp) {

      } else {
        this.router.navigate(['account/setup']);
      }
    });
  }

  saveUser() {
    this.document.set(this.user);
  }

  isStudent(){
    console.log(Math.pow(2,Roles.student.valueOf()) + "&" +  this.user.role)
    if((Math.pow(2,Roles.student.valueOf()) & this.user.role) == 0) {
      return false;
    } else {
      return true;
    }
  }

}
