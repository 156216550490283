import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountsComponent } from './accounts/accounts.component';
import { LoginComponent } from './accounts/login/login.component';
import { LogoutComponent } from './accounts/logout/logout.component';
import { SetupComponent } from './accounts/setup/setup.component';
import { SignedInGuard } from './guards/signed-in.guard';
import { DeniedComponent } from './dashboard/denied/denied.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [SignedInGuard], children: [
    { path: '', redirectTo: 'denied', pathMatch: 'full' },
    { path: 'denied', component: DeniedComponent },
  ]},
  { path: 'account', component: AccountsComponent, children: [
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent, canActivate: [SignedInGuard] },
    { path: 'setup', component: SetupComponent, canActivate: [SignedInGuard] }
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
