import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginRedirectService {

  url:string = "";

  constructor(private router:Router) { }

  login() {
    this.url = this.router.url;
    this.router.navigate(['account/login']);
  }

}
