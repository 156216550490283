import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

/**
 * Authentification manager
 */
export class AuthService {

		/**
		 * Creates a AuthService instance
		 * @param {AngularFireAuth} afAuth Firebase Authentification instance
		 */
		constructor(private afAuth: AngularFireAuth) {}

		/**
		 * Returns a boolean representing the authentification state
		 * @returns {Observable<boolean>} Auth state
		 */
		isAuthenticated() : Observable<boolean> {
				return this.user.pipe(map(user => !!user));
		}

		/**
		 * Gets the currently authenticated user
		 * @returns {Observable<User>} Current user
		 */
		get user(): Observable<User> {
			return this.afAuth.user;
		}
		
		/**
		 * Logins with the default login method
		 * @returns {Promise<any>} Login result
		 */
		logIn(): Promise<any> {
			return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
		}

		/**
		 * Logins using Facebook
		 * @returns {Promise<any>} Login result
		 */
		logInFacebook() : Promise<any> {
			return this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider());
		}

		/**
		 * Logins using Google
		 * @returns {Promise<any>} Login result
		 */
		logInGoogle(): Promise<any> {
			return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
		}

		/**
		 * Logins using Twitter
		 * @returns {Promise<any>} Login result
		 */
		logInTwitter(): Promise<any> {
			return this.afAuth.auth.signInWithPopup(new auth.TwitterAuthProvider());
		}

		/**
		 * Logout of current user
		 */
		logOut(): Promise<void> {
				return this.afAuth.auth.signOut();
		}
}
