import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from 'firebase';
import { AuthService } from 'src/app/services/auth.service';
import { AbstraktyUserService } from 'src/app/services/abstrakty-user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface Ocupation {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

  uid: string;
  form: FormGroup;
  user:Observable<User>;
  error:boolean = false;

  ocupations: Ocupation[] = [
    {value: 'student', viewValue: 'Estudiante'},
    {value: 'teacher', viewValue: 'Maestro'},
    {value: 'otra', viewValue: 'Otra'}
  ];

  constructor(private _formBuilder: FormBuilder, 
    private authService: AuthService, 
    private userService: AbstraktyUserService,
    private httpClient: HttpClient) { }
  

  ngOnInit() {
    this.form = this._formBuilder.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      mail: ['', Validators.required],
      ocupation: ['', Validators.required],
      university: ['', Validators.required]
    });
    this.form.controls['mail'].disable();
    this.user = this.authService.user;
    this.user.subscribe(u => {
      this.userService.loadUser(u.uid);
      this.userService.user.id = u.uid;
      this.userService.user.mail = u.email;
      this.userService.user.picture = u.photoURL;
      var names:string[] = u.displayName.split(' ');
      var controls = this.form.controls;
      switch (names.length) {
        case 1:
          controls['name'].setValue(names[0]);
          break;
        case 2:
          controls['name'].setValue(names[0]);
          controls['lastName'].setValue(names[1]);
          break;
        case 3:
          controls['name'].setValue(names[0]);
          controls['lastName'].setValue(names[1] + " " + names[2]);
          break;
        case 4:
          controls['name'].setValue(names[0] + " " + names[1]);
          controls['lastName'].setValue(names[2] + " " + names[3]);
          break;
        default:
          controls['name'].setValue(names[0]);
          var name:string = "";
          names.forEach(e => {
            if(!(e == names[0])) {
              name += e;
              name += ' ';
            }
          });
          controls['lastName'].setValue(name);
          break;
      }
      controls['mail'].setValue(u.email);
    });
  }

  setupAccount() {
    this.userService.user.setUp = true;
    this.userService.user.role = 1;
    this.userService.user.university = this.form.controls['university'].value;
    this.userService.user.name = this.form.controls['name'].value;
    this.userService.user.lastName = this.form.controls['lastName'].value;
    this.userService.user.ocupation = this.form.controls['ocupation'].value;
    const params = new HttpParams().set('uid', this.userService.user.id).set('email', this.userService.user.mail);
    this.httpClient.get(environment.api.TwilioTokenGenerator, {params}).subscribe(response => {
      var body = JSON.parse(response['body']);
      this.userService.user.twiloToken = body['token'];
      this.userService.saveUser();
      console.log(body['token']);
    });
  }

}
