import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'firebase';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AbstraktyUserService } from 'src/app/services/abstrakty-user.service';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit {

  user:Observable<User>;

  constructor(private authService: AuthService, private router:Router, private userService:AbstraktyUserService) {
    this.user = this.authService.user;
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['account/login']);
  }

  isStudent() {
    return this.userService.isStudent();
  }

}
