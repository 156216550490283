import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { User } from 'firebase';
import { AbstraktyUserService } from 'src/app/services/abstrakty-user.service';
import { LoginRedirectService } from 'src/app/services/login-redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component controller
 */
export class LoginComponent implements OnInit {

    /**
     * Current authentified user
     */  
    user: Observable<User>;
    
    /**
     * Creates a Login Component instance
     * @param {AuthService} authService Authentification Service instance
     * @param {Router} router Router instance
     */
		constructor(private authService: AuthService, private aus:AbstraktyUserService, private router: Router, private LoginRedirect:LoginRedirectService) {
        this.user = this.authService.user;
        this.user.subscribe(u => {
          if(u) {
            aus.loadUser(u.uid);
          }
          if(LoginRedirect.url == "/account/logout") {
            router.navigate(['']);
          } else {
            router.navigate([LoginRedirect.url]);
          }      
        });
        
		}

    /**
     * Component initialization sequence
     */
		ngOnInit() {}

    /**
     * Default login button handler
     */
		login() {
        this.authService.logIn().then(u => {
          console.log(u);
        });
    }
    
    /**
     * Google login button handler
     */
    loginGoogle() {
      this.authService.logInGoogle().then(u => {
        console.log(u);
      });
    }

    /**
     * Facebook login button handler
     */
    loginFacebook() {
      this.authService.logInFacebook().then(u => {
        console.log(u);
      });
    }

		/**
     * Logout button handler
     */
		logout() {
				this.authService.logOut();
		}

}
