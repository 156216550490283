import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'firebase';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  user:Observable<User>;

  constructor(private authService: AuthService, private router:Router) {
    this.user = this.authService.user;
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['account/login']);
  }

  isMobile() {
    var ua = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      return true;
    } else {
      return false;
    }
  }

}
